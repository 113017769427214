body {
  padding-top: 4.5rem;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background-color: #212121 !important;
    color: #E0E0E0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursorHand{
  cursor:pointer;
}

.MuiInputBase-input{
  color:#E0E0E0 !important;
}

.MuiSelect-icon{
  color:#E0E0E0 !important;
}

.MuiSvgIcon-root{
  color:#E0E0E0 !important;
}

.basic-navbar-nav{
  color:#E0E0E0 !important;
}

input{
  color:#E0E0E0 !important;
}

.MuiCheckbox-root{
  color:#E0E0E0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked{
  color:rgba(255,191,97,1) !important;
}

textarea{
  color:#E0E0E0 !important;
}

.MuiInput-underline:before{
  border-bottom: 1px solid #E0E0E0 !important;
}

.MuiInput-underline:after{
  border-bottom: 1px solid rgba(255,191,97,1) !important;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover{
  background-color: rgba(255,191,97,1) !important;
  color:#212121 !important;
}

fieldset > legend{
  color:#E0E0E0 !important;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #E0E0E0;
  -webkit-box-shadow: 0 0 0px 1000px #212121 inset;
  transition: background-color 5000s ease-in-out 0s;
}


.checkBox{
  color:#E0E0E0 !important;
}
.MuiOutlinedInput-notchedOutline{
  border-color:#E0E0E0 !important;

}

label{
  color:#E0E0E0 !important;
}

.label-black{
  color:rgba(0,0,0,0.87) !important;
}

.input-black > div > input{
  color:rgba(0,0,0,0.87) !important;
}

.paper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.avatar {
  margin: 8px;
  background-color: rgba(255,191,97,1) !important;
}

.form {
  width:100%;
  margin-top: 8px;
}

.submit {
  margin: 24px 0 16px !important;
  background-color:rgba(255,191,97,1) !important;
}

a{
  color:rgba(255,191,97,1) !important
}

.spinner{
    position:fixed;
    left:50%;
    top:50%;
    margin:10px 0 10px;
    z-index:9999;
  }
  .btnBack{
    cursor: pointer;
    color: rgba(255,191,97,1);
  }